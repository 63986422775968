import React, { useState, useCallback, useContext, useMemo } from 'react';
import styles from './App.module.scss';
import classnames from 'classnames';
import ohThePlacesWeGo from './images/oh-the-places-we-go.jpg';
import joshKissesAllison from './images/josh-kisses-allison.jpg';
import sheSaidBet from './images/she-said-bet.jpg';
import rsvpWhyDontYou from './images/rsvp-why-dont-you.jpg';
import { Flower } from './Flower';
import { Greetings } from './content/greetings';
import { Events } from './content/events';
import { Accomodations } from './content/accomodations';
import { Places } from './content/places';
import { Registry } from './content/registry';
import { RSVP } from './content/rsvp';

const NavigationItem = ({ title, onClick, onBack, href }) => {
  return <div className='col-12 text-center margin-left-none margin-bottom-small'>
    {onBack ? <a href='#' className='paper-btn margin-none' onClick={onBack}>&lt;</a> : null}
    <a href={href} target='_blank' className='paper-btn margin-none' onClick={onClick}>{title}</a>
  </div>;
}

const page = {
  home: 'Home',
  greetings: 'Greetings',
  events: 'Events',
  rsvp: 'RSVP',
  accomodations: 'Accomodations',
  places: 'Our Favorite Places',
  registry: 'Registry'
};

const PageContext = React.createContext(page.home);
const PageProvider = ({ children }) => {
  const [current, setPage] = useState(page.home);
  const go = useCallback((id) => () => setPage(id), [setPage]);
  return (
    <PageContext.Provider
      value={{
        current, api: {
          goToHome: go(page.home),
          goToGreetings: go(page.greetings),
          goToWhen: go(page.events),
          goToRSVP: go(page.rsvp),
          goToAccomodations: go(page.accomodations),
          goToPlaces: go(page.places),
          goToRegistry: go(page.registry)
        }
      }}
    >{children}</PageContext.Provider>
  )
}

const Navigation = ({ className, go }) => {
  const { current, api } = useContext(PageContext);

  const allLinks = useMemo(() => [
    { title: page.greetings, onClick: api.goToGreetings },
    { title: page.events, onClick: api.goToWhen },
    { title: page.rsvp, onClick: api.goToRSVP },
    { title: page.accomodations, onClick: api.goToAccomodations },
    { title: page.places, onClick: api.goToPlaces },
    { title: page.registry, href: 'https://www.amazon.com/wedding/registry/2M4O7NTXUDNJ4' },
  ], [api]);

  const visibleLinks = useMemo(() =>
    current !== page.home ? allLinks.filter((l) => l.title === current) : allLinks,
    [current, allLinks]
  );

  return (
    <nav className={classnames(className, 'row', 'margin-none')}>
      {visibleLinks.length > 1
        ? visibleLinks.map((link, i) => <NavigationItem key={i} {...link} />)
        : visibleLinks.map((link, i) => <NavigationItem key={i} {...link} onBack={api.goToHome} />)}
    </nav>
  );
}

const GridItem = ({ children, className }) => {
  return <div className={classnames('no-z-index', 'padding-small', 'sm-12', 'md-12', 'lg-3', className)}>
    {children}
  </div>;
};

const Header = () => {
  const { api } = useContext(PageContext);
  return (
    <a onClick={api.goToHome} href='#' className='noHoverChange'><h1 className='text-center'>Allison <Flower /> Joshua</h1></a>
  );
}

const Body = () => {
  const { current } = useContext(PageContext);

  if (current === page.home) {
    return null;
  }
  if (current === page.greetings) {
    return <Greetings />;
  }
  if (current === page.events) {
    return <Events />;
  }
  if (current === page.accomodations) {
    return <Accomodations />;
  }
  if (current === page.places) {
    return <Places />;
  }
  if (current === page.registry) {
    return <Registry />;
  }
  if (current === page.rsvp) {
    return <RSVP />;
  }
};

function App() {
  return (
    <PageProvider>
      <header>
        <Header />
      </header>
      <div className='row flex-spaces'>
        <GridItem><Navigation /></GridItem>
      </div>
      <div className='row flex-spaces flex-center'>
        <div className={classnames('no-z-index', 'padding-small', 'sm-12', 'md-12', 'lg-6', styles.center)}>
          <Body />
        </div>
      </div>
      {/* <Flower /> */}
    </PageProvider>
  );
}

export default App;