import React from 'react';

export const Greetings = () => {
    return <>
        <h2>Dearest Friends and Family,</h2>
        <p>
            We will start with the bad news. We are no longer having a large wedding reception in Kansas this year. The good news
            is two-fold, though. First, we are still getting married on Friday, August 14th with a small wedding reception at a Winery
            in Missouri. Second, YOU MADE THE CUT! Although we had to trim our guest list substantially, we wanted to
            include our families and our bridal party in our intimate celebration. You have molded us into who we are today and we
            would love to celebrate our special day with you by our sides.
        </p>
        <p>
            We are very aware of the COVID-19 pandemic and thus we want to emphasize that we absolutely do not want anyone
            to feel obligated to join us. PLEASE do what is best for you and your family regarding travel. Though this is being
            sent to less than 50 people, it is going to 10 different states. We completely understand any hesitations with traveling to
            Kansas City and we will celebrate another time with those who cannot make it. It will be a beautiful day for us and
            anyone who can join us is a bonus!
        </p>
    </>;
}