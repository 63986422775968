import React from 'react';

export const Flower = () => {
    return <div className='flower'>
        <div className='fl-container'>
            <div className="fl-droplet" id="no1"></div>
            <div className="fl-droplet" id="no2"></div>
            <div className="fl-droplet" id="no3"></div>
            <div className="fl-droplet" id="no4"></div>
            <div className="fl-droplet" id="no5"></div>
            <div className="fl-droplet" id="no6"></div>
            <div className="fl-droplet" id="no7"></div>
            <div className="fl-droplet" id="no8"></div>
            <div className="fl-droplet" id="no9"></div>
            <div className="fl-droplet" id="no10"></div>
        </div>
    </div>;
}