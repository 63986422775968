import React from 'react';

export const Events = () => {
    return <>
        <h2>Friday, August 14th</h2>
        <ul>
            <li><h3>5:00pm</h3> Arrive at <a href='http://www.belvoirwinery.com/' target='_blank'>Belvoir Winery</a> and grab a drink!</li>
            <li><h3>5:30pm</h3> Ceremony starts at the Gazebo. Bring a drink with you and please be seated by 5:20pm</li>
            <li><h3>6:00pm-10:00pm</h3> Indoor Reception on 2nd floor of Winery in the 1900 room with dinner, drinks, and dancing</li>
        </ul>
        <h2>Saturday, August 15th</h2>
        <ul>
            <li><h3>1:00pm – 5:00pm</h3> Grill & yard games at our house: <a href='https://goo.gl/maps/bHd1wXpNYN4H6Fpd6' target='_blank'>1522 East 100th St, Kansas City MO 64131</a></li>
        </ul>
    </>;
}