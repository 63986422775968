import React from 'react';

export const Accomodations = () => {
    return <>
        <p>
            Belvoir Winery has a 9-room Inn on the 3rd floor, which is where we will be staying Friday night! Call (816) 200-1811 for
            availability (we have the rooms reserved for our wedding so they will show ‘no vacancy’ online) and use promo code
            SUMMER25 for 25% off.
            </p>
        <p>
            There are also a few hotels (Holiday Inn, Hampton Inn, etc.) within a few miles of the reception
            in Liberty, MO. If you wish to stay close to our house for Saturday night, we recommend the Courtyard by Marriot
            Kansas City South (816) 941-3333, or anywhere in Overland Park, Kansas. Ubers, Lyfts, and Taxis are also available.
        </p>
    </>;
}