import React from 'react';

export const Places = () => {
    return <>
        <h2>BBQ</h2>
        <ul>
            <li><a href='https://www.joeskc.com/' target='_blank'>Joe’s KC</a> <em>The Ribs, The Z Man, The Fries</em></li>
            <li><a href='https://q39kc.com/' target='_blank'>Q39</a> <em>Modern BBQ, fantastic sandwiches</em></li>
            <li><a href='https://www.jackstackbbq.com/' target='_blank'>Jack Stack</a> <em>Best sides, Most comfortable</em></li>
            <li><a href='https://slapsbbqkc.com/' target='_blank'>Slap's BBQ</a> <em>Juicy, Sweet, Josh's first hole-in-the-wall</em></li>
        </ul>

        <h2>Coffee</h2>
        <ul>
            <li><a href='https://theroasterie.com/' target='_blank'>The Roasterie</a> <em>A Kansas City Classic</em></li>
            <li><a href='https://messengercoffee.co/' target='_blank'>Messenger Coffee</a> <em>Hip and Open</em></li>
            <li><a href='https://www.broadwayroasting.com/' target='_blank'>Broadway Cafe & Roasting Company</a> <em>Delicious Coffee</em></li>
        </ul>

        <h2>Pizza</h2>
        <ul>
            <li><a href='https://illazzarone.org/' target='_blank'>Il Lazzarone</a> <em>Neopolitan</em></li>
            <li><a href='https://www.rosatispizza.com/' target='_blank'>Rosati's</a> <em>Deep Dish</em></li>
            <li><a href='https://deweyspizza.com/' target='_blank'>Dewey's</a> <em>Meatball pizza is a winner</em></li>
            <li><a href='https://minskys.com/' target='_blank'>Minsky's</a> A Kansas City Classic</li>
            <li><a href='https://www.gosarpinos.com/' target='_blank'>Sarpino's</a> Westport Location is 🔥</li>
        </ul>

        <h2>Ice Cream</h2>
        <ul>
            <li><a href='http://bettyraes.com/' target='_blank'>Betty Rae's</a> <em>Best Ice Cream in town</em></li>
            <li><a href='https://www.fairwaycreamery.com/' target='_blank'>Fairway Creamery</a> <em>Quaint little neighborhood joint</em></li>
        </ul>

        <h2>Fancier Dinner Spots</h2>
        <ul>
            <li><a href='https://cafedesamiskc.com/' target='_blank'>Café des Amis</a> <em>French, Roses, Wine, Where Allison said 'Yes'</em></li>
            <li><a href='https://silocanyonfarms.com/' target='_blank'>Silo Modern Farmhouse</a> <em>American food with a Country Club view</em></li>
            <li><a href='http://grunauerkc.com/' target='_blank'>Grünauer</a> <em>Hearty German food with scrumptious desserts</em></li>
            <li><a href='http://majestickc.com/' target='_blank'>The Majestic</a> <em>Private, Jazzy, Great Steaks, Classic Cocktails</em></li>
        </ul>

        <h2>Breweries & Wineries</h2>
        <ul>
            <li><a href='https://kcbier.com/' target='_blank'>KC Bier Co</a></li>
            <li><a href='https://doubleshiftbrewing.com/' target='_blank'>Double Shift</a></li>
            <li><a href='https://www.boulevard.com/' target='_blank'>Boulevard</a></li>
            <li><a href='https://www.amigoni.com/' target='_blank'>Amigoni</a></li>
            <li><a href='https://kcwineworks.com/' target='_blank'>KC Wineworks</a></li>
            <li><a href='https://www.kcwineco.com/' target='_blank'>KC Wine Co</a></li>
        </ul>
    </>;
}